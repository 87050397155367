body {
  margin: 0;
  max-width: 100%;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  max-width: 100%;
  overflow-x: hidden;
  }
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.scroll__container{
  position: fixed;
  right: 5%;
  bottom: 5%;
  z-index: 1;
}


.scroll__container__out{
  position: fixed;
  right: 20%;
  bottom: 5%;
  z-index: 1;
}

.adfit_right{
  position: fixed;
  right: 2%;
  top: 15%;
  z-index: 1;
}


.adfit_left{
  position: fixed;
  left: 2%;
  top: 15%;
  z-index: 1;
}


.iframe__container{
  position: fixed;
  right: 2%;
  bottom: 20%;
  z-index: 1;
}

#top{
  font-weight: bold;
  font-size: 15px;
  padding :15px 13px;
  background-color: rgb(122, 122, 122);
  color:#fff;
  border: 1px solid rgb(122, 122, 122);
  border-radius: 10%;
  outline: none;
  cursor: pointer;
}



#top:hover{
  color :rgb(0, 0, 0);
}


.paging {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}


.share {
  position: absolute;
  right: 1%;
}


.more {
  position: absolute;
  right: 1%;
}

.right_button {
  display: flex;
  justify-content: right;
  margin-right: 0;
}

.dark_back {
  /* background-color: #212529; */
  background-color: black;
}

.white {
  color:rgb(210, 204, 193);
}


.blurEffect {
  filter: blur(10px);
  -webkit-filter: blur(10px);
}